@keyframes typing {
    from { width: 0; }
  }
  
  .typing {
    width: 100%;
    white-space: pre-wrap;
    animation: typing 2s steps(100);
  }

  span {
    font-weight: bolder;
    color: rgb(0, 255, 0);;
  }

  #cv {
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }

  #cv div {
    border:2px groove green;
    margin: 20px;
    padding: 20px;
  }

  #lsCategories div{
    margin: 0;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
  }