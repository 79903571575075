* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.terminal {
    background-color: black;
    color: rgb(0, 170, 0);
    font-family: 'Courier New', Courier, monospace;
    padding:15px;
    height: 100vh;
    width: 100%;
    font-size:1.2em;
}
