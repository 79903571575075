#bootUp {
    overflow-y: auto; /* Enable vertical scrolling */
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
  
#bootUp::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
}
  
  